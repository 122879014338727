import type { ReactElement } from 'react';
import { css } from '@emotion/react';
import { Grid, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import type { MarkdownRemarkFrontmatterGlobalSection } from '../../../../../generated/types';
import { SignUpButton } from '../../../../components';
import { useBreakpoints } from '../../../../components/hooks/useBreakpoints';
import { FadeInViewport } from '../../../../components/layout';
import { MarkdownTypography } from '../../../../components/typography';
import { GlobalVideo } from './GlobalVideo';

type PpGlobalSection = {
  content: MarkdownRemarkFrontmatterGlobalSection;
};

export function GlobalSection({ content }: PpGlobalSection): ReactElement | null {
  const theme = useTheme();
  const { isExtraSmall } = useBreakpoints();
  const styles = {
    container: css`
      background-color: #eeedee;
      // originally theme.palette.websiteBrand.greyscale.lightGrey
      // need a new video with the correct background
      border-radius: ${theme.borderRadius.xl};
      overflow: hidden;
      ${theme.breakpoints.down('lg')} {
        padding-bottom: ${theme.spacing(10)};
      }
      ${theme.breakpoints.down('sm')} {
        padding-bottom: ${theme.spacing(4)};
      }
    `,
    copyGrid: css`
      padding: ${theme.spacing(10, 5, 10, 10)};
      ${theme.breakpoints.down('lg')} {
        padding: ${theme.spacing(10)};
      }
      ${theme.breakpoints.down('sm')} {
        padding: ${theme.spacing(5)};
      }
    `,
  };
  if (!content) {
    return null;
  }
  return (
    <Stack alignItems="center">
      <Grid container css={styles.container} maxWidth="lg">
        <Grid item css={styles.copyGrid} lg={7} xs={12}>
          <FadeInViewport timeout={1000}>
            <Stack alignItems="flex-start" spacing={2.5}>
              <MarkdownTypography variant={isExtraSmall ? 'bodyHeadingM' : 'h6'}>
                {content.title}
              </MarkdownTypography>
              <Typography variant="paragraphBodyL">{content.subtitle}</Typography>
              <SignUpButton color="primary" variant="outlined">
                {content.button}
              </SignUpButton>
            </Stack>
          </FadeInViewport>
        </Grid>
        <Grid item lg={5} xs={12}>
          <GlobalVideo
            maxWidth={`${theme.breakpoints.values.sm}px`}
            videoSrc="https://res.cloudinary.com/noahapp/video/upload/v1685702563/Website/videos/globe-slow-animation_pjcbgu.mp4"
          />
        </Grid>
      </Grid>
    </Stack>
  );
}
