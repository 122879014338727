import type { ReactElement } from 'react';
import { css } from '@emotion/react';
import { Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { FadeInViewport } from '../../../../components';
import { useBreakpoints } from '../../../../components/hooks/useBreakpoints';

type PpHeroCopy = {
  subtitle: string | null | undefined;
  title: string | null | undefined;
};

export function HeroCopy({ subtitle, title }: PpHeroCopy): ReactElement {
  const theme = useTheme();
  const { isExtraSmall } = useBreakpoints();
  const styles = {
    title: css`
      margin: ${theme.spacing(0, 18)};
      ${theme.breakpoints.down('md')} {
        margin: ${theme.spacing(0, 12)};
      }
      ${theme.breakpoints.down('sm')} {
        margin: 0;
      }
    `,
  };
  return (
    <Stack spacing={isExtraSmall ? 2.5 : 5}>
      <FadeInViewport timeout={1000}>
        <Typography component="h1" css={styles.title} variant="h4">
          {title}
        </Typography>
      </FadeInViewport>
      <FadeInViewport style={{ transitionDelay: '250ms' }} timeout={1250}>
        <Typography color="text.dark" variant="paragraphBodyL">
          {subtitle}
        </Typography>
      </FadeInViewport>
    </Stack>
  );
}
