import type { ReactElement } from 'react';
import { css } from '@emotion/react';
import { Card, CardContent, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { BitcoinIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/BitcoinIcon';
import { LockIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/LockIcon';
import { RewardsIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/RewardsIcon';
import { cssMixins } from '@noah-labs/fe-shared-ui-components';
import type { MarkdownRemarkFrontmatterCardsSectionItems } from '../../../../../generated/types';
import { LocalizedButton } from '../../../../components/i18n/LocalizedButton';
import { MarkdownTypography } from '../../../../components/typography/MarkdownTypography';
import { bitcoinIconOverride } from '../../../checkout/utils';

type PpFeatureCard = {
  card: MarkdownRemarkFrontmatterCardsSectionItems;
  index: number;
};

export function FeatureCard({ card, index }: PpFeatureCard): ReactElement {
  const theme = useTheme();
  const styles = {
    card: css`
      ${cssMixins.bgImageCoverNoRepeat(card.image as string)}
      width: 350px;
      height: 450px;
      padding: ${theme.spacing(6, 3)};
      justify-content: space-between;
      text-align: left;
      ${theme.breakpoints.down('sm')} {
        width: 100%;
        width: 300px;
        height: 400px;
      }
    `,
    cardContent: css`
      align-items: flex-start;
      color: ${index === 1
        ? theme.palette.websiteBrand.greyscale.black
        : theme.palette.websiteBrand.greyscale.white};
    `,
  };

  function getCardIcon(): ReactElement {
    switch (index) {
      case 0:
        return <LockIcon color="primary" />;
      case 1:
        return <RewardsIcon color="primary" />;
      default:
        return <BitcoinIcon css={bitcoinIconOverride(theme.palette.websiteBrand.primary)} />;
    }
  }

  const icon = getCardIcon();

  return (
    <Card component={Stack} css={styles.card} elevation={3}>
      <CardContent component={Stack} css={styles.cardContent} spacing={2.5}>
        <MarkdownTypography variant="h6">{card.copy}</MarkdownTypography>
        <LocalizedButton color="primary" href={card.link as string} variant="contained">
          {card.button}
        </LocalizedButton>
      </CardContent>
      {icon}
    </Card>
  );
}
