import type { ReactElement } from 'react';
import { css } from '@emotion/react';
import { Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export function GetAppQRCode(): ReactElement {
  const theme = useTheme();
  const styles = {
    container: css`
      align-items: center;
      border: 2px solid ${theme.palette.websiteBrand.primary};
      border-radius: ${theme.borderRadius.lg};
      padding: ${theme.spacing(0.5, 0.5, 0.5, 1)};
      text-align: left;
      width: 225px;
    `,
    qrCode: css`
      border: 4px solid ${theme.palette.websiteBrand.primary};
      border-radius: ${theme.borderRadius.lg};
      height: 100px;
      width: 100px;
    `,
  };
  return (
    <Stack css={styles.container} direction="row">
      <Typography color="text.secondary" variant="paragraphBodyM">
        Download the NOAH app
      </Typography>
      <img
        alt="Scan to download the NOAH App"
        css={styles.qrCode}
        src="https://res.cloudinary.com/noahapp/image/upload/v1686209605/Website/get-app_-_2_i2esye.png"
      />
    </Stack>
  );
}
