import type { ReactElement } from 'react';
import { Fragment } from 'react';
import { css } from '@emotion/react';
import { Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import type { MarkdownRemarkFrontmatterBuySellSection } from '../../../../../generated/types';
import { SvgSell } from '../../../../components/animated/SvgSell';
import { useBreakpoints } from '../../../../components/hooks/useBreakpoints';
import { FadeInViewport } from '../../../../components/layout';
import { BuySellItem } from './BuySellItem';
import { PaymentsMethods } from './PaymentsMethods';

type PpBuySellSection = {
  content: MarkdownRemarkFrontmatterBuySellSection;
};

export function BuySellSection({ content }: PpBuySellSection): ReactElement | null {
  const theme = useTheme();
  const { isExtraSmall } = useBreakpoints();

  if (!content) {
    return null;
  }
  const styles = {
    container: css`
      padding: ${theme.spacing(10, 0)};
      text-align: center;
      align-items: center;
      width: 100%;
    `,
  };
  return (
    <Stack css={styles.container}>
      <Stack maxWidth="md" spacing={10} width="100%">
        <FadeInViewport timeout={1000}>
          <Typography variant={isExtraSmall ? 'bodyHeadingS' : 'h4'}>{content.title}</Typography>
        </FadeInViewport>
        {Array.isArray(content.items) && (
          <Fragment>
            <BuySellItem item={content.items[0]}>
              <PaymentsMethods />
            </BuySellItem>
            <BuySellItem reverse item={content.items[1]}>
              <SvgSell />
            </BuySellItem>
          </Fragment>
        )}
      </Stack>
    </Stack>
  );
}
