import type { ReactElement } from 'react';
import { css } from '@emotion/react';
import { Card, Stack } from '@mui/material';
import Marquee from 'react-fast-marquee';

export function PaymentsMethods(): ReactElement {
  const styles = {
    card: css`
      position: relative;
      height: 100%;
      width: 250px;
      height: 300px;
    `,
    shadow: css`
      position: absolute;
      left: 0;
      width: 100%;
      height: 100px;
    `,
    shadowBottom: css`
      bottom: 0;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 70%);
    `,
    shadowTop: css`
      top: 0;
      background: linear-gradient(180deg, rgba(255, 255, 255, 1) 30%, rgba(255, 255, 255, 0) 100%);
    `,
  };
  return (
    <Card
      alignItems="center"
      component={Stack}
      css={styles.card}
      elevation={2}
      justifyContent="center"
    >
      <Marquee direction="down" style={{ height: '300px', width: '250px' }}>
        <img
          alt="visa"
          src="https://res.cloudinary.com/noahapp/image/upload/v1685706575/Website/payments-methods/svg-visa_ibbll5.svg"
          width="100%"
        />
        <img
          alt="mastercard"
          src="https://res.cloudinary.com/noahapp/image/upload/v1685706575/Website/payments-methods/svg-mastercard_udg78m.svg"
          width="100%"
        />
        <img
          alt="applepay"
          src="https://res.cloudinary.com/noahapp/image/upload/v1685706575/Website/payments-methods/svg-applepay_o67ov8.svg"
          width="100%"
        />
        <img
          alt="googlepay"
          src="https://res.cloudinary.com/noahapp/image/upload/v1685706575/Website/payments-methods/svg-googlepay_j10fmc.svg"
          width="100%"
        />
      </Marquee>
      <div css={[styles.shadow, styles.shadowTop]} />
      <div css={[styles.shadow, styles.shadowBottom]} />
    </Card>
  );
}
