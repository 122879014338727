import type { ReactElement } from 'react';
import { css } from '@emotion/react';
import { Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import type {
  MarkdownRemarkFrontmatterCardsSection,
  MarkdownRemarkFrontmatterCardsSectionItems,
} from '../../../../../generated/types';
import { AppStoreButtons } from '../../../../components/buttons';
import { useBreakpoints } from '../../../../components/hooks/useBreakpoints';
import { FadeInViewport } from '../../../../components/layout';
import { GetAppQRCode } from '../../../../components/qr';
import { CardsSectionCarousel } from './CardsSectionCarousel';
import { FeatureCard } from './FeatureCard';

type PpCardsSection = {
  content: MarkdownRemarkFrontmatterCardsSection;
};

export function CardsSection({ content }: PpCardsSection): ReactElement | null {
  const theme = useTheme();
  const { isExtraSmall, isMedium } = useBreakpoints();
  if (!content || !content.items) {
    return null;
  }
  const styles = {
    container: css`
      width: 100%;
      padding: ${theme.spacing(10, 0)};
      text-align: center;
      align-items: center;
      ${theme.breakpoints.down('lg')} {
        padding: ${theme.spacing(10, 3)};
      }
    `,
  };
  return (
    <Stack alignItems="center">
      <Stack alignItems="center" css={styles.container} maxWidth="md" spacing={10} width="100%">
        <FadeInViewport timeout={1000}>
          <Typography variant={isExtraSmall ? 'bodyHeadingM' : 'h4'}>{content.title}</Typography>
        </FadeInViewport>
      </Stack>
      {isMedium ? (
        <CardsSectionCarousel
          cards={content.items as Array<MarkdownRemarkFrontmatterCardsSectionItems>}
        />
      ) : (
        <FadeInViewport timeout={1000}>
          <Stack direction="row" spacing={5}>
            {content?.items.map((item, index) => {
              if (!item) {
                return null;
              }
              return <FeatureCard key={item.id} card={item} index={index} />;
            })}
          </Stack>
        </FadeInViewport>
      )}
      <Stack alignItems="center" css={styles.container} maxWidth="md" spacing={10} width="100%">
        <FadeInViewport timeout={1000}>
          <Typography variant={isMedium ? 'h5' : 'h2'}>{content.closing}</Typography>
        </FadeInViewport>
        <AppStoreButtons direction="row" justifyContent="space-between" maxWidth="md" />
        <GetAppQRCode />
      </Stack>
    </Stack>
  );
}
