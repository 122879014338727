import type { ReactElement } from 'react';
import { css } from '@emotion/react';
import { Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import type {
  MarkdownRemarkFrontmatterHeroSection,
  MarkdownRemarkFrontmatterInvestors,
} from '../../../../../generated/types';
import { AppStoreButtons } from '../../../../components/buttons';
import { GrowInViewport } from '../../../../components/layout/GrowInViewport';
import { HeroCopy } from './HeroCopy';
import { HeroWrapper } from './HeroWrapper';

type PpHeroSection = {
  content: MarkdownRemarkFrontmatterHeroSection;
  investors: Array<MarkdownRemarkFrontmatterInvestors>;
};

export function HeroSection({ content, investors }: PpHeroSection): ReactElement | null {
  const theme = useTheme();

  if (!content) {
    return null;
  }
  const styles = {
    image: css`
      width: 100%;
      height: auto;
    `,
    imageWrapper: css`
      transition: transform ease-in-out 0.5s;
      ${theme.breakpoints.up('lg')} {
        :hover {
          transform: scale(1.1);
        }
      }
    `,
    title: css`
      margin: ${theme.spacing(0, 18)};
      ${theme.breakpoints.down('md')} {
        margin: ${theme.spacing(0, 12)};
      }
      ${theme.breakpoints.down('sm')} {
        margin: 0;
      }
    `,
  };
  return (
    <HeroWrapper investors={investors}>
      <Stack alignItems="center" spacing={5} width="100%">
        <HeroCopy subtitle={content.subtitle} title={content.title} />
        <AppStoreButtons direction="row" justifyContent="space-between" maxWidth="md" />
        {content.image?.src && (
          <Stack css={styles.imageWrapper} maxWidth="sm" width="100%">
            <GrowInViewport timeout={1000}>
              <img
                alt={content.image.alt || 'smartphones using the NOAH app'}
                css={styles.image}
                height="447px"
                src={content.image.src}
                width="601px"
              />
            </GrowInViewport>
          </Stack>
        )}
      </Stack>
    </HeroWrapper>
  );
}
