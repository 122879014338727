import type { ReactElement } from 'react';
import { Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import Marquee from 'react-fast-marquee';
import type {
  MarkdownRemarkFrontmatterInvestors,
  MarkdownRemarkFrontmatterPartners,
  Maybe,
} from '../../../../../generated/types';

type PpHeroMarquee = {
  items?: Maybe<Array<MarkdownRemarkFrontmatterInvestors | MarkdownRemarkFrontmatterPartners>>;
};

const StyledMarqueeImage = styled('img')(() => ({
  height: 'auto',
  maxHeight: '50px',
  maxWidth: '150px',
  width: 'auto',
}));

const StyledMarqueeStack = styled(Stack)(({ theme }) => ({
  alignItems: 'center',
  height: '140px',
  justifyContent: 'space-around',
  minWidth: '100vw',
  padding: theme.spacing(0, 4),
  [theme.breakpoints.down('sm')]: {
    height: '70px',
  },
}));

export function HeroMarquee({ items }: PpHeroMarquee): ReactElement | null {
  if (!items) {
    return null;
  }

  return (
    <Marquee pauseOnClick>
      <StyledMarqueeStack direction="row" spacing={8}>
        {items.map((item) => {
          if (!item?.logo || !item.name) {
            return null;
          }
          return <StyledMarqueeImage key={item.name} alt={item.name || ''} src={item.logo} />;
        })}
      </StyledMarqueeStack>
    </Marquee>
  );
}
