import { useEffect } from 'react';
import { css } from '@emotion/react';
import { navigate, useLocation } from '@gatsbyjs/reach-router';
import { Container } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { cssMixins } from '@noah-labs/fe-shared-ui-components';
import type { PageProps } from 'gatsby';
import { graphql } from 'gatsby';
import type {
  MarkdownRemarkFrontmatterBuySellSection,
  MarkdownRemarkFrontmatterCardsSection,
  MarkdownRemarkFrontmatterGetStartedSection,
  MarkdownRemarkFrontmatterGlobalSection,
  MarkdownRemarkFrontmatterHeroSection,
  MarkdownRemarkFrontmatterInvestors,
  NewHomePageQuery,
} from '../../generated/types';
import { navigateToLocalizedPath } from '../components/i18n/utils';
import { HomepageTemplate } from '../components/templates';
import {
  BuySellSection,
  CardsSection,
  GetStartedSection,
  GlobalSection,
  HeroSection,
} from '../modules/personal';

type PpIndexPage = PageProps<NewHomePageQuery>;

function IndexPage({ data: { content, investors } }: PpIndexPage): React.ReactElement {
  const theme = useTheme();
  const location = useLocation();

  useEffect(() => {
    navigateToLocalizedPath(location.pathname, navigate);
  }, [location.pathname]);

  const styles = {
    container: css`
      padding: ${theme.spacing(0, 11.5)};
      ${cssMixins.colCentered}
      ${theme.breakpoints.down('lg')} {
        padding: ${theme.spacing(0, 3)};
      }
    `,
  };

  return (
    <HomepageTemplate seo={content?.frontmatter?.seo}>
      <HeroSection
        content={content?.frontmatter?.heroSection as MarkdownRemarkFrontmatterHeroSection}
        investors={investors?.frontmatter?.investors as Array<MarkdownRemarkFrontmatterInvestors>}
      />
      <Container disableGutters css={styles.container} maxWidth="xl">
        <GetStartedSection
          content={
            content?.frontmatter?.getStartedSection as MarkdownRemarkFrontmatterGetStartedSection
          }
        />
        <BuySellSection
          content={content?.frontmatter?.buySellSection as MarkdownRemarkFrontmatterBuySellSection}
        />
        <GlobalSection
          content={content?.frontmatter?.globalSection as MarkdownRemarkFrontmatterGlobalSection}
        />
      </Container>
      <CardsSection
        content={content?.frontmatter?.cardsSection as MarkdownRemarkFrontmatterCardsSection}
      />
    </HomepageTemplate>
  );
}

export const query = graphql`
  query NewHomePage($locale: String!) {
    content: markdownRemark(frontmatter: { layout: { eq: "personal" }, locale: { eq: $locale } }) {
      frontmatter {
        seo {
          title
          description
          image
        }
        locale
        heroSection {
          image {
            src
            alt
          }
          title
          subtitle
        }
        getStartedSection {
          title
          subtitle
          button
          steps {
            step
            id
          }
        }
        buySellSection {
          title
          items {
            id
            title
            copy
            button
            link
          }
        }
        globalSection {
          title
          subtitle
          button
        }
        cardsSection {
          title
          closing
          items {
            id
            button
            copy
            link
            image
          }
        }
      }
    }
    investors: markdownRemark(frontmatter: { layout: { eq: "team" }, locale: { eq: $locale } }) {
      frontmatter {
        investors {
          id
          logo
          name
          href
        }
      }
    }
  }
`;

export default IndexPage;
