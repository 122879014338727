import type { ReactElement } from 'react';
import { useRef } from 'react';
import { css } from '@emotion/react';
import { Stack } from '@mui/material';
import type { CSSObject } from '@mui/material';
import { useInViewport } from 'react-in-viewport';
import ReactPlayer from 'react-player';

type PpGlobalVideo = {
  maxWidth: CSSObject['maxWidth'];
  videoSrc: string;
};

export function GlobalVideo({ maxWidth, videoSrc }: PpGlobalVideo): ReactElement {
  const ref = useRef(null);
  const { inViewport } = useInViewport(ref);

  const styles = {
    video: css`
      max-width: ${maxWidth};
    `,
  };
  return (
    <Stack ref={ref} alignItems="center" justifyContent="center" width="100%">
      <ReactPlayer
        loop
        muted
        playsinline
        css={styles.video}
        height="100%"
        playing={inViewport}
        url={videoSrc}
        width="100%"
      />
    </Stack>
  );
}
