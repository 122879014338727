import type { ReactElement } from 'react';
import { css } from '@emotion/react';
import { Grid, ListItem, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { List } from '@noah-labs/fe-shared-ui-components';
import type { MarkdownRemarkFrontmatterGetStartedSectionSteps } from '../../../../../generated/types';
import { FadeInViewport } from '../../../../components';
import { MarkdownTypography } from '../../../../components/typography';

type PpGetStartedSteps = {
  steps: Array<MarkdownRemarkFrontmatterGetStartedSectionSteps>;
};

export function GetStartedSteps({ steps }: PpGetStartedSteps): ReactElement {
  const theme = useTheme();
  const styles = {
    copy: css`
      a {
        text-decoration: none;
        color: ${theme.palette.websiteBrand.primary};
      }
    `,
    gridItem: css`
      height: 100%;
      padding: ${theme.spacing(0, 10, 10, 0)};
      ${theme.breakpoints.down('lg')} {
        padding: ${theme.spacing(0, 0, 5)};
      }
    `,
    index: css`
      background-color: ${theme.palette.websiteBrand.primary};
      border-radius: 50%;
      margin-right: ${theme.spacing(2)};
      align-items: center;
      justify-content: center;
      min-width: 25px;
      height: 25px;
    `,
    listItem: css`
      padding: 0;
      flex-direction: row;
      align-items: flex-start;
    `,
  };
  return (
    <List>
      <Grid container>
        {steps.map((step, index) => {
          if (!step) {
            return null;
          }
          return (
            <Grid key={step.id} item css={styles.gridItem} lg={6} xs={12}>
              <FadeInViewport style={{ transitionDelay: `${index * 100}ms` }} timeout={500}>
                <ListItem component={Stack} css={styles.listItem}>
                  <Stack css={styles.index}>
                    <Typography color="text.white" variant="paragraphBodyS">
                      {index + 1}
                    </Typography>
                  </Stack>
                  <MarkdownTypography css={styles.copy} variant="paragraphBodyM">
                    {step.step}
                  </MarkdownTypography>
                </ListItem>
              </FadeInViewport>
            </Grid>
          );
        })}
      </Grid>
    </List>
  );
}
