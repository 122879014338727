import type { ReactElement } from 'react';
import { css } from '@emotion/react';
import { Grid, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import type { PpWC } from '@noah-labs/fe-shared-ui-shared';
import type {
  MarkdownRemarkFrontmatterBuySellSectionItems,
  Maybe,
} from '../../../../../generated/types';
import { useBreakpoints } from '../../../../components/hooks/useBreakpoints';
import { LocalizedButton } from '../../../../components/i18n/LocalizedButton';
import { FadeInViewport } from '../../../../components/layout';

type PpBuySellItem = {
  item: Maybe<MarkdownRemarkFrontmatterBuySellSectionItems>;
  reverse?: boolean;
} & PpWC;

export function BuySellItem({
  children,
  item,
  reverse = false,
}: PpBuySellItem): ReactElement | null {
  const theme = useTheme();
  const { isExtraSmall } = useBreakpoints();

  if (!item) {
    return null;
  }

  const styles = {
    childrenWrapper: css`
      ${theme.breakpoints.down('lg')} {
        margin-bottom: ${theme.spacing(5)};
      }
    `,
    container: css`
      ${theme.breakpoints.down('lg')} {
        max-width: ${`${theme.breakpoints.values.sm}px`};
      }
    `,
    copy: css`
      align-items: flex-start;
      text-align: left;
    `,
  };

  return (
    <Stack alignItems="center" width="100%">
      <Grid container css={styles.container}>
        <Grid item lg={6} order={{ lg: reverse ? 1 : 0 }} xs={12}>
          <FadeInViewport timeout={1000}>
            <Stack
              alignItems="center"
              css={styles.childrenWrapper}
              height="100%"
              justifyContent="center"
            >
              {children}
            </Stack>
          </FadeInViewport>
        </Grid>
        <Grid item lg={6} order={{ lg: reverse ? 0 : 1 }} xs={12}>
          <FadeInViewport timeout={500}>
            <Stack css={styles.copy} spacing={2.5}>
              <Typography variant={isExtraSmall ? 'bodyHeadingL' : 'h6'}>{item.title}</Typography>
              <Typography color="text.dark" variant="paragraphBodyL">
                {item.copy}****
              </Typography>
              {item.link && (
                <LocalizedButton color="primary" href={item.link} variant="contained">
                  {item.button}
                </LocalizedButton>
              )}
            </Stack>
          </FadeInViewport>
        </Grid>
      </Grid>
    </Stack>
  );
}
