import type { ReactElement, ReactNode } from 'react';
import { css } from '@emotion/react';
import { Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import type { MarkdownRemarkFrontmatterCardsSectionItems } from '../../../../../generated/types';
import { CustomCarousel, CustomCarouselArrow } from '../../../../components/carousel';
import { useBreakpoints } from '../../../../components/hooks/useBreakpoints';
import { FeatureCard } from './FeatureCard';

type PpCardsSectionCarousel = {
  cards: Array<MarkdownRemarkFrontmatterCardsSectionItems>;
};

export function CardsSectionCarousel({ cards }: PpCardsSectionCarousel): ReactElement {
  const theme = useTheme();
  const isExtraSmall = useBreakpoints();
  const styles = {
    carousel: css`
      width: 100%;
      .slide {
        display: flex;
        align-items: center;
        padding-bottom: ${theme.spacing(10)};
      }
    `,
  };
  return (
    <Stack maxWidth="sm" width="100%">
      <CustomCarousel
        autoPlay
        centerMode
        infiniteLoop
        preventMovementUntilSwipeScrollTolerance
        showIndicators
        swipeable
        centerSlidePercentage={100}
        css={styles.carousel}
        interval={3000}
        renderArrowNext={(onClickHandler, hasNext, label): false | ReactNode =>
          hasNext && (
            <CustomCarouselArrow
              background={theme.palette.websiteBrand.greyscale.black}
              color={theme.palette.websiteBrand.greyscale.white}
              label={label}
              side="right"
              onClick={onClickHandler}
            />
          )
        }
        renderArrowPrev={(onClickHandler, hasPrev, label): false | ReactNode =>
          hasPrev && (
            <CustomCarouselArrow
              background={theme.palette.websiteBrand.greyscale.black}
              color={theme.palette.websiteBrand.greyscale.white}
              label={label}
              side="left"
              onClick={onClickHandler}
            />
          )
        }
        showArrows={!isExtraSmall}
        showStatus={false}
        showThumbs={false}
        swipeScrollTolerance={50}
      >
        {cards?.map((card, index) => <FeatureCard key={card.id} card={card} index={index} />)}
      </CustomCarousel>
    </Stack>
  );
}
