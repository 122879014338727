import type { ReactElement } from 'react';
import { useRef } from 'react';
import type { GrowProps } from '@mui/material';
import { Grow } from '@mui/material';
import { useInViewport } from 'react-in-viewport';

export function GrowInViewport({ children, ...rest }: GrowProps): ReactElement {
  const ref = useRef(null);
  const { inViewport } = useInViewport(ref);

  return (
    <Grow ref={ref} {...rest} in={inViewport}>
      {children}
    </Grow>
  );
}
