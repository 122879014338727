import type { ReactElement } from 'react';
import { useRef } from 'react';
import { css } from '@emotion/react';
import { Fade, Grid, Slide, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useInViewport } from 'react-in-viewport';
import type {
  MarkdownRemarkFrontmatterGetStartedSection,
  MarkdownRemarkFrontmatterGetStartedSectionSteps,
} from '../../../../../generated/types';
import { SignUpButton } from '../../../../components';
import { useBreakpoints } from '../../../../components/hooks/useBreakpoints';
import { GetStartedSteps } from './GetStartedSteps';

type PpGetStartedSection = {
  content: MarkdownRemarkFrontmatterGetStartedSection;
};

export function GetStartedSection({ content }: PpGetStartedSection): ReactElement | null {
  const theme = useTheme();
  const viewportRef = useRef(null);
  const containerRef = useRef(null);
  const { inViewport } = useInViewport(viewportRef);
  const { isExtraSmall, isMedium } = useBreakpoints();
  if (!content) {
    return null;
  }
  const styles = {
    container: css`
      background-color: ${theme.palette.websiteBrand.greyscale.lightGrey};
      padding: ${theme.spacing(10)};
      border-radius: ${theme.borderRadius.xl};
      width: 100%;
      overflow: hidden;
      ${theme.breakpoints.down('lg')} {
        padding: ${theme.spacing(10, 10, 5)};
      }
      ${theme.breakpoints.down('sm')} {
        padding: ${theme.spacing(5, 5, 0)};
      }
    `,
    copy: css`
      align-items: flex-start;
      ${theme.breakpoints.down('lg')} {
        align-items: center;
        text-align: center;
      }
    `,
    imageWrapper: css`
      align-items: center;
      justify-content: center;
      padding-right: ${theme.spacing(10)};
      height: 100%;
      ${theme.breakpoints.down('lg')} {
        display: none;
      }
    `,
  };
  return (
    <Stack ref={viewportRef} css={styles.container} maxWidth={isMedium ? 'sm' : 'lg'} width="100%">
      <Grid container>
        <Grid item lg={4}>
          <Fade in={inViewport} timeout={1000}>
            <Stack ref={containerRef} css={styles.imageWrapper}>
              <Slide container={containerRef.current} direction="up" in={inViewport} timeout={1000}>
                <img
                  alt="NOAH App"
                  src="https://res.cloudinary.com/noahapp/image/upload/v1685544771/Website/phone-2x_nzyc2x.png"
                  width="100%"
                />
              </Slide>
            </Stack>
          </Fade>
        </Grid>
        <Grid item lg={8} xs={12}>
          <Stack spacing={isMedium ? 5 : 10}>
            <Stack alignItems="flex-start" css={styles.copy} spacing={2.5}>
              <Typography variant={isExtraSmall ? 'h4' : 'h6'}>{content.title}</Typography>
              <Typography variant={isExtraSmall ? 'bodyHeadingXS' : 'bodyHeadingS'}>
                {content.subtitle}
              </Typography>
              <SignUpButton color="primary" variant="contained">
                {content.button}
              </SignUpButton>
            </Stack>
            <GetStartedSteps
              steps={content.steps as MarkdownRemarkFrontmatterGetStartedSectionSteps[]}
            />
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
}
