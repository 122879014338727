import type { ReactElement } from 'react';
import { css } from '@emotion/react';
import { Container, Stack } from '@mui/material';
import type { CSSObject } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { coreThemeConstants } from '@noah-labs/fe-shared-ui-components';
import type { PpWC } from '@noah-labs/fe-shared-ui-shared';
import type { MarkdownRemarkFrontmatterInvestors, Maybe } from '../../../../../generated/types';
import { HeroMarquee } from './HeroMarquee';

type PpHeroWrapper = {
  investors: Maybe<Array<MarkdownRemarkFrontmatterInvestors>>;
  paddingTop?: CSSObject['paddingTop'];
} & PpWC;

export function HeroWrapper({
  children,
  investors,
  paddingTop = coreThemeConstants.spacing(10),
}: PpHeroWrapper): ReactElement {
  const theme = useTheme();
  const styles = {
    container: css`
      ${theme.breakpoints.down('lg')} {
        padding: ${theme.spacing(0, 3)};
      }
    `,
    stack: css`
      width: 100%;
      padding-top: ${paddingTop};
      align-items: center;
      justify-content: space-between;
      text-align: center;
      overflow: hidden;
      ${theme.breakpoints.down('sm')} {
        min-height: 90vh;
      }
    `,
  };
  return (
    <Stack css={styles.stack}>
      <Container disableGutters css={styles.container} maxWidth="md">
        {children}
      </Container>
      <HeroMarquee items={investors} />
    </Stack>
  );
}
